.container {
  padding: 0 2rem;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.authPanel {
  position: relative;

  .title {
    margin-bottom: 15px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 38px;
    line-height: 46px;
  }
}

.errorMessage {
  position: absolute;
  bottom: -35px;
  color: #f16a6a;
  font-size: 14px;
}